import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import MemberList from './components/MemberList';
import Login from './components/Login';

function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    // Function to handle login
    const handleLogin = () => setIsAuthenticated(true);
    const handleLogout = () => setIsAuthenticated(false);

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Walnut Grove Cemetery Management</h1>
                    <button className="menu-toggle" onClick={toggleMenu}>
                        <FaBars size={24} />
                    </button>
                </header>

                {isMenuOpen && (
                    <nav className="menu">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/map">Map</Link></li>
                            {!isAuthenticated ? (
                                <li><Link to="/login">Login</Link></li>
                            ) : (
                                <>
                                    <li><Link to="/members">Members</Link></li>
                                    <li><Link to="/add-member">Add Member</Link></li>
                                    <li><button onClick={handleLogout}>Logout</button></li>
                                </>
                            )}
                        </ul>
                    </nav>
                )}

                <div className="description">
                    <p>Welcome to Walnut Grove Cemetery. Manage member records, view plots, and explore our map.</p>
                </div>

                <Routes>
                    <Route path="/" element={<h2>Home</h2>} />
                    <Route path="/map" element={<h2>Interactive Map Coming Soon</h2>} />
                    <Route path="/login" element={<Login onLogin={handleLogin} />} />
                    {isAuthenticated ? (
                        <>
                            <Route path="/members" element={<MemberList />} />
                            <Route path="/add-member" element={<h2>Add Member Form Coming Soon</h2>} />
                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/login" />} />
                    )}
                </Routes>
            </div>
        </Router>
    );
}

export default App;